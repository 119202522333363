import { routes } from "./routes.js"
import { createRouter, createWebHistory } from "vue-router"

import { useAuthStore } from "/src/stores/auth"
import * as Sentry from "@sentry/vue"
import { storeToRefs } from "pinia"

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const addMetaTags = (metaTags) => {
    const VUE_META_TAGS_ATTR = "data-vue-meta"

    // Remove tags that were added in other views
    Array.from(document.querySelectorAll(`[${VUE_META_TAGS_ATTR}]`)).map((el) =>
        el.parentNode.removeChild(el)
    )

    const metaTagsArray = Object.entries(metaTags || {})
    metaTagsArray
        .map(([metaTagKey, metaTagValue]) => {
            const tag = document.createElement("meta")
            tag.setAttribute(metaTagKey, metaTagValue)
            tag.setAttribute(VUE_META_TAGS_ATTR, "")

            return tag
        }) // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag))
}

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    const {
        isLoggedIn,
        isRegistrationComplete,
        isSuperAdmin,
        currentOrganization,
        currentUser,
    } = storeToRefs(authStore)

    addMetaTags(to.meta.metaTags)

    // Retrieve user if not there (NOTE: the store is cleared on page refresh)
    if (!isLoggedIn.value) {
        try {
            await authStore.retrieveUser()
        } catch (error) {
            return to.name === "login" ? next() : next({ name: "login" })
        }
    }

    // CHECK USER REGISTRATION IS COMPLETE
    if (!isRegistrationComplete.value && to.name !== "register") {
        // If the user hasn't completed registration or does not belong to any project,
        //  then redirect to registration view
        return next({ name: "register" })
    } else if (isRegistrationComplete.value && to.name === "register") {
        // If the user tries to access registration but is already registered, redirect
        // them home.
        return next({ name: "home" })
    } else if (!currentUser.value.is_active && to.name !== "inactive-user") {
        // If the user is not active, always redirect to the inactive-user page
        return next({ name: "inactive-user" })
    } else if (to.name === "inactive-user" && currentUser.value.is_active) {
        // If the user is acrive they can't visit the inactive-user page
        return next({ name: "home" })
    }

    if (to.meta.requiresAuth) {
        // Assertions
        if (!currentUser.value)
            throw "User must be already specified at this point"

        // Add user/organization details to sentry errors
        Sentry.setUser({
            email: currentUser.value.email,
            id: currentUser.value.id,
        })
        if (currentOrganization.value) {
            Sentry.setTags({
                organization: currentOrganization.value.name,
                organization_id: currentOrganization.value.id,
            })
        }

        // If user is trying to access a super-admin only page then redirect them home
        if (to.meta.requiresSuperAdmin && !isSuperAdmin.value)
            return next({ name: "home" })

        // Else go to the requested view
        next()
    } else if (to.meta.requiresAnonymous && isLoggedIn) {
        // Some routes, e.g. login, require the user not to be logged in. If
        //  a logged in user tries to acess them, redirect to the home page.
        next({ name: "home" })
    } else next()
})

export default router
