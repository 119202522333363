import { createApp } from "vue"
import "./style.css"
import App from "./App.vue"
import router from "/src/router/index"
import { createPinia } from "pinia"
import VueFeather from "vue-feather"
import * as Sentry from "@sentry/vue"

const app = createApp(App)

// Register Sentry
// Configuration reference: https://docs.sentry.io/platforms/javascript/guides/vue/
if (import.meta.env.VITE_SENTRY_ENABLE === "true") {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration({ router })],
        environment: import.meta.env.VITE_ENVIRONMENT,
        initialScope: {
            tags: { app: "frontend" },
        },
        tracesSampleRate: 0.0,
    })
}

app.use(router)
app.use(createPinia())
app.component(VueFeather.name, VueFeather)
app.mount("#app")
