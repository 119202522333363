<template>
    <!-- NOTE: the id is necessary to use Vue's Teleport component to move a component
        that logically belongs somewhere else to this node in the DOM (e.g. toolbar).-->
    <div
        id="pageHeader"
        class="z-10 bg-gray-25 pt-6 pb-2 flex flex-col justify-start gap-2"
    >
        <slot></slot>
    </div>
</template>
