import { reactive } from "vue"
import { RouteLocationRaw } from "vue-router"
const crumbMapping = reactive<{ [key: string]: any }>({})

export const useCrumbs = () => {
    const setDocumentCrumb = (documentId: string, filename: string) => {
        crumbMapping.document = {
            name: filename,
            to: {
                name: "document-view",
                params: { id: documentId },
            } as RouteLocationRaw,
        }
    }

    return { crumbMapping, setDocumentCrumb }
}
