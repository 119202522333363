<template>
    <div
        v-if="text != null"
        class="px-2 py-[2px] rounded-full text-xs font-medium text-center"
        :class="style"
    >
        {{ text }}
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

export type BadgeColour =
    | "default"
    | "primary"
    | "warning"
    | "success"
    | "error"

const props = withDefaults(
    defineProps<{ text: string; colour?: BadgeColour }>(),
    { colour: "default" }
)

const style = computed(() => {
    if (props.colour === "primary") return "bg-primary-50 text-primary-700"
    if (props.colour === "warning") return "bg-warning-100 text-warning-500"
    if (props.colour === "success") return "bg-success-50 text-success-700"
    if (props.colour === "error") return "bg-error-50 text-error-700"
    return "bg-gray-100 text-gray-700"
})
</script>
