<template>
    <template v-if="isLoggedIn">
        <NavBar
            class="fixed left-0 top-0"
            @logout="authStore.logout()"
        />

        <div
            class="content-grid absolute h-screen left-[--sidebar-size-collapsed] right-0 px-8"
        >
            <!-- FIXME: overflow-clip-margin only works in Chrome with overflow-clip on both axes -->
            <PageHeader
                v-if="route.name !== 'register'"
                class="content-grid-header mb-6 overflow-x-clip"
                style="overflow-clip-margin: 2rem"
            >
                <!-- <div
                    v-if="currentOrganization?.settings?.trial_end_date"
                    class="bg-warning-200/40 text-sm font-semibold text-gray-500 px-4 py-2 rounded-lg -mx-4 shadow-md w-full"
                >
                    Your trial will expire on:
                    {{ currentOrganization?.settings.trial_end_date }}
                </div> -->
                <EaiBreadcrumbs
                    :home-name="currentOrganization?.name ?? 'Home'"
                    :crumb-mapping="crumbMapping"
                />
            </PageHeader>

            <router-view
                class="content-grid-main absolute top-0 left-0 right-0 bottom-0 overflow-y-auto overflow-x-hidden"
            />
        </div>
    </template>
    <template v-else>
        <RouterView class="absolute top-0 left-0 right-0 bottom-0" />
    </template>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useAuthStore } from "/src/stores/auth"
import PageHeader from "/src/components/core/PageHeader.vue"
import { useRoute } from "vue-router"
import NavBar from "/src/components/core/nav/NavBar.vue"
import EaiBreadcrumbs from "./components/core/EaiBreadcrumbs.vue"
import { useCrumbs } from "./composables/crumbs"

const authStore = useAuthStore()
const { isLoggedIn, currentOrganization } = storeToRefs(authStore)

const route = useRoute()
const { crumbMapping } = useCrumbs()
</script>

<style>
.content-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "header"
        "main";
}

.content-grid > .content-grid-header {
    grid-area: header;
}

.content-grid > .content-grid-main {
    grid-area: main;
}
</style>
