<template>
    <div
        class="custom-select-container group flex flex-col gap-1.5 relative"
        :class="{
            'slc-small': size === 'small',
            'pointer-events-none opacity-40': disabled,
        }"
        tabindex="0"
        @blur="closeDropdown"
        @keydown.up.stop.prevent="highlightNext(true)"
        @keydown.down.stop.prevent="highlightNext(false)"
        @keydown.enter.stop="
            isOpen && highlightedOption
                ? selectOption(highlightedOption)
                : toggleDropdown()
        "
        @keydown.escape.stop="closeDropdown"
        @keyup.capture.stop="highlightOptionWithKeyboard"
    >
        <label
            v-if="label"
            class="text-sm font-medium text-gray-700 !normal-case"
        >
            {{ label }}
        </label>
        <div>
            <div
                class="custom-select group-focus-within:ring-4 group-focus-within:ring-[#f4ebff] relative"
                @click="toggleDropdown"
            >
                <span
                    v-if="modelValue === null || modelValue === undefined"
                    class="text-gray-500 !normal-case"
                >
                    {{ placeholder }}
                </span>
                <span v-else>{{ selectedOption?.label }}</span>
                <VueFeather
                    class="transition-all duration-200 ease-in-out origin-center text-gray-500"
                    :class="{ 'rotate-180': isOpen }"
                    :type="dropup ? 'chevron-up' : 'chevron-down'"
                    :size="size === 'small' ? '18px' : '20px'"
                />
            </div>

            <Transition name="dropdown-content">
                <div
                    v-show="isOpen"
                    class="bg-white rounded-lg shadow-lg mt-1 absolute flex flex-col overflow-clip w-full z-10"
                    :class="{ '-translate-y-full -top-2': dropup }"
                >
                    <input
                        v-if="searchable"
                        ref="searchInput"
                        v-model="searchOption"
                        type="text"
                        class="shrink grow"
                        placeholder="Search..."
                        @focus="isOpen = true"
                        @keydown.up.stop.prevent="highlightNext(true)"
                        @keydown.down.stop.prevent="highlightNext(false)"
                        @input="highlightedOptionIdx = 0"
                    />
                    <div class="overflow-y-scroll max-h-44">
                        <div
                            v-for="option in filteredOptions"
                            :key="option.label"
                            ref="optionElements"
                            :class="{
                                '!bg-primary-50 hover:!bg-primary-100':
                                    option.value === modelValue,
                                '!bg-gray-50':
                                    option.value === highlightedOption?.value,
                                'pointer-events-none opacity-40':
                                    disabledOptions?.includes(option.value),
                            }"
                            class="custom-option"
                            @click="selectOption(option)"
                        >
                            <span>{{ option.label }}</span>
                            <VueFeather
                                v-if="option.value === modelValue"
                                type="check"
                                class="text-primary-600"
                                :size="size === 'small' ? '18px' : '20px'"
                            />
                        </div>
                    </div>
                    <!--
                        We pass the open/close methods to the slot so that we can control
                        the state of the dropdown from there. For example, when we have
                        an input field in the dropdown, we need to ensure that the
                        dropdown stays open when we focus the input (because the dropdown
                        loses focus in that case). Note that we have to use methods
                        because we can't modify the variables directly.
                     -->
                    <slot
                        name="extra-content"
                        :close-dropdown="closeDropdown"
                        :open-dropdown="openDropdown"
                    ></slot>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watchEffect } from "vue"
import { ref, computed, watch, nextTick } from "vue"

export type SelectValue = string | number | null | undefined
export interface SelectOption {
    label: string
    value: SelectValue
}

const props = withDefaults(
    defineProps<{
        // We use 'modelValue' so that we can simply use v-model on the parent component
        // https://vuejs.org/guide/components/events.html#usage-with-v-model
        modelValue: SelectValue
        options: (SelectValue | SelectOption)[]
        placeholder?: string
        label?: string | null
        searchable?: boolean
        size?: "small" | "medium"
        dropup?: boolean
        disabled?: boolean
        disabledOptions?: SelectValue[]
    }>(),
    {
        placeholder: "Select an option",
        label: null,
        searchable: false,
        size: "medium",
        dropup: false,
        disabled: false,
        disabledOptions: () => [],
    }
)
const emit = defineEmits<{
    (e: "update:modelValue", value: SelectValue): void
    (e: "change", value: SelectValue): void
}>()

// If options are a list of strings/numbers transform them into a list of SelectOption
//  and apply the search filter.
const searchOption = ref("")
const standardizedOptions = computed(() => {
    return props.options.map((option) => {
        if (typeof option === "string")
            return { label: option, value: option } as SelectOption
        else if (typeof option === "number")
            return { label: `${option}`, value: option } as SelectOption
        else return option as SelectOption
    })
})
const filteredOptions = computed(() => {
    return standardizedOptions.value.filter((option) =>
        option.label.toLowerCase().includes(searchOption.value.toLowerCase())
    )
})

const selectOption = (option: SelectOption | null) => {
    emit("update:modelValue", option?.value)
    emit("change", option?.value)
    closeDropdown()
}
const selectedOption = computed(() => {
    if (props.modelValue === null || props.modelValue === undefined)
        return props.modelValue
    return standardizedOptions.value.find((o) => o.value === props.modelValue)
})

//  Handle opening/closing the dropdown
const isOpen = ref(false)
const closeDropdown = () => {
    searchOption.value = ""
    isOpen.value = false
}
const openDropdown = () => {
    searchOption.value = ""
    isOpen.value = true
}
const toggleDropdown = () => {
    isOpen.value ? closeDropdown() : openDropdown()
}

// Handle keyboard shortcuts to select previous/next option
const optionElements = ref<HTMLElement[] | null>(null)
const highlightedOptionIdx = ref<number | null>(null)
const highlightedOption = computed(
    () => filteredOptions.value[highlightedOptionIdx.value ?? -1]
)

const highlightNext = (reverse: boolean = false) => {
    isOpen.value = true
    let nextHighlightedOptionIdx =
        (highlightedOptionIdx.value ?? -1) + (reverse ? -1 : 1)

    const nOptions = filteredOptions.value.length
    nextHighlightedOptionIdx = (nextHighlightedOptionIdx + nOptions) % nOptions

    highlightedOptionIdx.value = nextHighlightedOptionIdx
}

/**
 * If the dropdown is not searchable, we want to highlight the option that
 * matches the first letter that the user types, mimicking the behaviour of the
 * native select element.
 */
const highlightOptionWithKeyboard = (e: KeyboardEvent) => {
    if (props.searchable) return

    const key = e.key.toLowerCase()

    // This should only be triggered by letters and numbers
    const validKeys = /^[a-z0-9]$/
    if (!validKeys.test(key)) return

    // Open the dropdown if it's not already open (do it only after we check it's a
    //  key that should indeed trigger this function)
    isOpen.value = true

    // Find all options that match the key
    const matchingIndices = standardizedOptions.value
        .map((o, idx) => (o.label.toLowerCase().startsWith(key) ? idx : -1))
        .filter((idx) => idx !== -1)

    if (matchingIndices.length === 0) return

    // If the highlighted option is one of the matching options, highlight the next one
    const i = matchingIndices.indexOf(highlightedOptionIdx.value ?? -1)

    if (matchingIndices.length > 1 && i !== -1) {
        highlightedOptionIdx.value =
            matchingIndices[(i + 1) % matchingIndices.length]
    } else {
        // Otherwise, highlight the first matching option
        highlightedOptionIdx.value = matchingIndices[0]
    }
}

// Automatically scroll the highlighted option into view
watchEffect(() => {
    optionElements.value?.[highlightedOptionIdx.value ?? -1]?.scrollIntoView({
        block: "center",
        behavior: "smooth",
    })
})

// If the dropdown is searchable, focus on the input when opened
const searchInput = ref<HTMLInputElement | null>(null)
if (props.searchable) {
    watch(isOpen, (newValue) => {
        nextTick(() => {
            if (newValue) searchInput.value?.focus()
        })
    })
}
</script>

<style lang="css">
@tailwind components;

@layer components {
    .custom-select-container {
        @apply text-md;
    }

    .custom-select {
        @apply cursor-pointer px-3.5 py-2 bg-white;
        @apply border-[1px] border-gray-300 rounded-lg shadow-sm shadow-gray-900/5;
        @apply flex flex-row justify-between items-center;
        @apply whitespace-nowrap;
    }

    .custom-option {
        @apply cursor-pointer px-3 py-2 bg-white hover:bg-gray-50;
        @apply flex flex-row justify-between items-center;
    }

    .custom-select-container.slc-small {
        @apply text-sm;
    }

    .slc-small .custom-select {
        @apply py-1;
    }
    .slc-small .custom-option {
        @apply py-1;
    }

    .slc-small input {
        @apply !py-0.5 !text-sm;
    }

    /* Animation of dropdown sheet */
    .dropdown-content-enter-active,
    .dropdown-content-leave-active {
        @apply transition-all duration-200 ease-in-out;
    }

    .dropdown-content-enter-from,
    .dropdown-content-leave-to {
        @apply opacity-0 -translate-y-1;
    }
}
</style>
